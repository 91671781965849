:root {
  --white: #fff;
  --black: black;

  --overlay-up: #eeeeee;
  --overlay-down: #e4e4e4;

  --purple: #7b4094;
  --purple-dark: #3a303f;
  --box-shadow: rgba(0, 0, 0, 0.25);
}

@font-face {
  font-family: "Poppins-Regular";
  src: url("../../fonts/Poppins-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Poppins-Bold";
  src: url("../../fonts/Poppins-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Poppins-SemiBold";
  src: url("../../fonts/Poppins-SemiBold.ttf") format("truetype");
}
@font-face {
  font-family: "Poppins-Light";
  src: url("../../fonts/Poppins-Light.ttf") format("truetype");
}
/* ============================================ */
/* ============================================ */
/* Utilities: Text */
.text {
  font-weight: normal;
  font-style: normal;
  line-height: normal;
  text-align: justify;
}
.text--light {
  margin: auto 0px;
  font-family: "Poppins-Light", sans-serif;
  font-size: 9px;
}
.text--regular {
  font-family: "Poppins-Regular", sans-serif;
  font-size: 10px;
}
.text--medium {
  font-family: "Poppins-Regular", sans-serif;
  font-size: 12px;
}
.text--medium-bold {
  font-family: "Poppins-SemiBold", sans-serif;
}
.text--large {
  font-family: "Poppins-Bold", sans-serif;
  font-size: 15px;
}
/* ================== */
/* Utilities: Rectangle */
.rectangle {
  display: block;
  width: 64px;
  height: 5px;
}
.rectangle--dark {
  background-color: var(--purple-dark);
}
.rectangle--white {
  background-color: var(--white);
}
/* ================== */
/* Utilities: Overlay */
.overlay {
  position: absolute;
  top: 0;
  -webkit-user-select: none;
  user-select: none;
}
.overlay--right {
  right: -70px;
  width: 350px;
  height: 180px;
  overflow: hidden;
}
.overlay--left {
  left: -200px;
}
.overlay__content {
  position: absolute;
  left: 180px;
}
.overlay__item {
  position: absolute;
  font-size: 80px;
}
.overlay__item--up {
  left: 40px;
  color: var(--overlay-up);
}
.overlay__item--down {
  top: 30px;
  color: var(--overlay-down);
}
/* ============================================ */
/* ============================================ */
* {
  margin: 0px;
  padding: 0px;
  font-family: "Poppins-Regular", sans-serif;
}
.body {
  width: 210mm;
  overflow-x: hidden;
}
.body__content {
  display: flex;
  flex-direction: column;
  width: 210mm;
  height: 297mm;
  overflow: hidden;
  overflow-y: hidden;
}
/* ================== */
/*  Block: Header */
.header {
  position: relative;
  width: 100%;
  padding-bottom: 20px;
  height: 100px;
}

.header__content {
  display: flex;
  align-items: center;
  margin: 30px 70px 0px;
  justify-items: center;
}
.header__content--primary {
  justify-content: space-between;
}
.header__content--secondary {
  justify-content: flex-end;
}

.logo {
  padding-right: 12px;
  border-right: 0.5px solid var(--white);
}
.header__container {
  display: flex;
  z-index: 2;
  gap: 12px;
  color: var(--white);
}
.header__background {
  display: flex;
  position: absolute;
  top: -50px;
  z-index: -1;
  margin-left: -10px;
  background-color: var(--purple);
  width: calc(100% + 20px);
  height: 130.63px;
  color: var(--white);
  transform: rotate(2.895deg);
  flex-shrink: 0;
}
/* ================== */
/*  Block: Main */
.main--proposta {
  padding-bottom: 100px;
}
.main__content {
  display: flex;
  flex-direction: column;
}
.main__content--primary {
  margin-top: 50px;
  margin: 0px 70px;
}
.main__content--secondary {
  color: var(--white);
}
.main__item {
  margin: 0px 70px;
}
/* ================== */
/*  Element: Aside */
.main__infotext {
  width: 653px;
}
.main__aside {
  display: flex;
  position: relative;
  float: right;
  flex-direction: column;
  align-items: center;
  top: 50px;
  margin-bottom: 110px;
  margin-left: 30px;
}
.info__city {
  width: 134px;
  text-align: left;
}
.aside__picture {
  display: flex;
  position: relative;
  justify-content: flex-end;
  width: 230px;
  height: 330px;
}
.aside__rectangle {
  position: absolute;
  bottom: 0px;
  left: 0px;
  z-index: 3;
  background: var(--purple);
  width: 134.102px;
  height: 24.668px;
  box-shadow: 4.811px 3.499px 0.35px 0px var(--box-shadow);
}
.aside__image {
  z-index: 2;
  border-radius: 0px 129.288px 0px 1.674px;
  width: 209.594px;
  height: 321.039px;
  object-fit: cover;
}
.aside__square {
  position: absolute;
  bottom: 12px;
  left: 10px;
  z-index: 1;
  background: var(--purple-dark);
  width: 134.102px;
  height: 143.811px;
  box-shadow: 4.811px 3.499px 0.35px 0px var(--box-shadow);
}
/* ================== */
/*  Element: Info */
.aside__info {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 30px;
  width: 253px;
}
.info__container {
  text-align: right;
}
.info__list {
  list-style: none;
}
.info__title {
  margin-right: 10px;
}
/* ================== */
/*  Element: Details */
.main__details {
  margin-top: 30px;
}
.main__header {
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
}
.main__title {
  align-self: flex-start;
  font-size: 35px;
  font-weight: normal;
  white-space: nowrap;
}

.main__subtitle {
  align-self: flex-end;
  text-transform: capitalize;
  white-space: nowrap;
}
.main__rectangle {
  margin-bottom: 30px;
  margin-left: 50px;
}
.main__rectangle-secondary {
  margin: 10px 0px 20px;
}
.main__text p {
  margin-bottom: 10px;
  color: var(--black);
}
/* ================== */
/*  Element: Themes */
.main__themes {
  display: flex;
  position: relative;
  justify-content: flex-end;
  width: 100%;
}
.themes__content {
  align-self: flex-end;
  margin-top: 20px;
  width: -moz-fit-content;
  width: fit-content;
}
.themes__title {
  font-size: 20px;
}
.themes__rectangle {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
  width: 100%;
  min-width: 280px;
  max-width: 320px;
}
.themes__list {
  display: grid;
  grid-template-columns: auto auto auto;
  grid-row: 10px;
  list-style: none;
}
.themes__line {
  margin: 0px 10px;
}
/* ================== */
/*  Block: Footer */
.footer {
  height: 100px;
}
.footer__background {
  display: block;
  padding-bottom: 100vh;
  background-color: var(--purple-dark);
  width: 120%;
  transform: rotate(-2deg);
}
/* ============================================ */
/* ============================================ */
.body__content--secondary {
  background-color: var(--purple-dark);
}

.main__container {
  display: flex;
  flex-direction: column;
  gap: 60px;
}
.main__sub-container {
  display: flex;
  flex-direction: column;
  gap: 30px;
}
/* -- */
.item__service {
  display: flex;
  justify-content: space-between;
}
.service__contractor {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}
.contractor__text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 2px;
}
.service__type {
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  width: 300px;
}
.service__text {
  margin: 0px;
  position: relative;
}
.investment__text {
  text-transform: uppercase;
}
/* -- */
.item__payment {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.payment__text {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.payment__list {
  align-self: flex-start;
  margin: 10px 0px 0px 20px;
}
/* -- */
.item__footer {
  display: flex;
  justify-content: space-between;
}
.footer__title {
  margin-bottom: 5px;
}
.footer__service-time {
  display: flex;
  flex-direction: column;
  align-items: center;
}
/* -- */
.main__budget {
  display: flex;
  justify-content: center;
  background-color: var(--purple);
  padding: 10px 0px;
  color: var(--white);
}
/* -- */
.item__signature {
  color: var(--white);
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin: 0px 70px;
}
.item__mail {
  display: flex;
  justify-content: center;
}
