:root {
  --purple: #7b4094;
  --purple-soft: #9155aa;
  --purple-dark: #3a303f;
}
* {
  scrollbar-width: thin; /* Para navegadores Firefox */
}

*::-webkit-scrollbar {
  width: 12px; /* Largura da scrollbar */
}

*::placeholder {
  color: #bbbbbb;
}
.page-body {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.body-page {
  overflow: hidden;
  overflow-y: hidden;
}
.main {
  display: flex;
  height: 98vh;
}
.main--reverse {
  flex-direction: row-reverse;
}
.form__area {
  width: calc(100% - 810px);
}

.form {
  display: flex;
  /* position: absolute; */
  flex-direction: column;
  width: calc(100% - 810px);
  margin-left: -24px;
  height: 100vh;
  overflow-y: scroll;
  /* box-shadow: 10px 0px 10px rgb(226, 226, 226); */
}

.form__item {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  margin-bottom: 20px;
}
.form__item--img {
  width: 100px;
}
.form__container {
  width: 80%;
  margin: 50px auto;
}
.form__container--dark {
  background-color: var(--purple-dark);
}
.input {
  margin: 5px 0px 15px;
  display: flex;
  height: 20px;
  padding: 5px 10px;
  width: 100%;
  border-radius: 5px;
  border: 0.5px solid rgb(185, 185, 185);
  outline: none;
  font-size: 0.8vw;
}
.label {
  font-size: 0.8vw;
}
.form__details {
  cursor: pointer;
  padding-bottom: 20px;
}
.form__summary:hover {
  color: #7b4094;
}
.form__summary {
  border-bottom: 1px solid #7b4094;
  margin-bottom: 20px;
  padding-bottom: 5px;
}
.input--textarea {
  width: 100%;
  height: 200px;
  font-size: 0.8vw;
}
.form__flex {
  display: flex;
  gap: 50px;
  width: 100%;
}

.form__observation {
  margin-left: 10px;
  color: var(--purple);
  font-size: small;
  white-space: nowrap;
  font-size: 0.8vw;
}
.label--required::after {
  content: "*";
  color: var(--purple);
}
.button {
  align-items: center;
  margin: 0px auto;
  margin-top: 20px;
  width: 80%;
  background-color: var(--purple);
  border: none;
  color: white;
  padding: 10px;
  cursor: pointer;
  transition: 300ms;
}
.button:hover {
  margin-top: 20px;
  background-color: var(--purple-soft);
  border: none;
  color: white;
  padding: 10px;
  cursor: pointer;
}
.reverse-btn {
  display: none;
  height: 100%;
  top: 0px;
  left: 52.5%;
  width: 50px;
  position: fixed;
  background-color: white;
  box-shadow: 10px 0px 10px rgb(226, 226, 226);
  border: none;
}
.reverse-btn:hover,
.reverse-btn:focus {
  border: none;
}
