/* LoginPage.css */
.login-background {
  background-color: rgb(233, 233, 233);
}
.login-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 40px);
  margin: 0px auto;
  width: 50vw;
  background-color: white;
  border-top: #7b4094 20px solid;
  border-bottom: #7b4094 20px solid;
}

.login-header {
  margin-bottom: 20px;
}

.login-header__icon {
  width: 100px;
  height: 100px;
  fill: #7b4094;
}
.login-page h2 {
  margin: 20px 0px;
}
.login-form {
  width: 300px;
  align-items: normal;
}

.login-form__field {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.login-form__label {
  display: block;

  margin-bottom: 5px;
}

.login-form__input {
  width: calc(100% - 32px);
  padding: 8px 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

.login-form__button {
  width: 100%;
  padding: 10px;
  background-color: green;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.login-form__button:hover {
  background-color: rgb(18, 19, 18);
}
