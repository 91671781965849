.btn-no {
  margin: 0;
  padding: 0;
  border: none;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  background-color: transparent;
  color: inherit;
  cursor: pointer;
  box-sizing: border-box;
}
.btn-no:focus {
  border: none;
}

.header-item {
  display: flex;
  position: relative;
  justify-content: end;
  background-color: #7b4094;
  font-size: 12px;
  padding: 16px 64px;
  align-items: center;
  border-bottom: #e6aaff solid 4px;
  user-select: none;
  z-index: 3;
}
.header-item--login {
  height: 100px;
}
.header__icon {
  height: 35px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.header__sub-container {
  display: flex;
  gap: 8px;
  align-items: center;
}

.header__status {
  margin: auto 0px;
  height: fit-content;
  padding: 8px 16px;
  background-color: white;
  border-radius: 25px;
  color: black;
}

.header__status-text {
  color: green;
  font-weight: bold;
}

.header__username {
  font-weight: 600;
  letter-spacing: 0.6px;
}

.header__profile {
  display: flex;
  gap: 8px;
}
.header__profile-img {
  border-radius: 100%;
  height: 50px;
  width: 50px;
  object-fit: cover;
}

.header__profile-menu {
  cursor: pointer;
  margin: auto 0px;
  position: relative;
}

.header__menu {
  position: absolute;
  transform: translateX(-90%);
  color: black;
  padding: 16px;
  background-color: white;
  display: none;
  border-radius: 5px;
  border-top-right-radius: 0px;
  justify-content: end;
  bottom: -80px;
  z-index: 4;
}
.header__menu--visible {
  display: flex;
}
.header__menu::after {
  content: "";
  position: absolute;
  right: 0px;
  border-style: solid;
  display: block;
  top: -15px;
  border-width: 15px 0 0px 15px; /* vary these values to change the angle of the vertex */
  border-color: #ffffff transparent;
  transform: scale(1, -1);
}

.header__menu-area {
  padding: 10px;
  width: 200px;
  height: 200px;
  top: -100px;
  left: 50px;
  position: absolute;
  z-index: 1;
}

.header__menu-btn {
  margin: 0;
  border: none;
  font-family: inherit;
  font-size: 14px;
  font-weight: inherit;
  background-color: transparent;
  color: inherit;
  cursor: pointer;
  box-sizing: border-box;
  display: flex;
  padding: 8px 16px;
  width: 153px;
  gap: 8px;
  z-index: 4;
}

.header__menu-btn:hover {
  border-radius: 5px;
  color: white;
  fill: white;
  background-color: #7b4094;
}

.header__menu-icon {
  height: 17px;
  width: 17px;
}
.header__menu-icon path {
  stroke: #7b4094;
}

.header__menu-btn:hover .header__menu-icon path {
  stroke: white;
}

.header__overlay {
  transition: ease opacity 500ms;
  opacity: 0;
  padding: 10px;
  background-color: rgba(10, 10, 10, 0.2);
  position: absolute;
  transform: translateX(-90%);
  top: -30px;
  width: 120vw;
  height: 0vh;
}
.header__overlay--visible {
  opacity: 1;
  z-index: 2;
  height: 100vh;
}
